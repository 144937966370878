import { Box, Button, Card, Input } from "../components";
import { umbracoApi, usePostAuthLoginMutation } from "../store/api/umbraco-api";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { login } from "../store/session/actions/user.actions";
import LogoBox from "../components/header/logo-box";
import { CircularProgress, Typography } from "@mui/joy";
import { get } from "lodash/fp";
import { TAUGA_AI_LOGO_1 } from "../images/images";
import useNavigateWithProvider from "../components/hooks/useNavigateWithProvider";

const Login = () => {
  const dispatch = useDispatch();
  const [mutate, { isSuccess, isLoading, data, isError }] =
    usePostAuthLoginMutation();
  const [fetchOrgs, { data: orgsData, isLoading: isLoadingOrgs }] =
    umbracoApi.useLazyGetOrganizationsQuery();
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const { provider } = useParams(); // Get the provider from the URL params
  const [logoToDisplay, setLogoToDisplay] = useState<string[] | undefined>(); // State for dynamic logo
  const [fetchLoginProvider, { data: loginProvider }] =
    umbracoApi.useLazyGetLoginProvidersByProviderUrlNameQuery();

  // fetch the login provider
  useEffect(() => {
    if (provider) {
      const normalizedProvider = provider.toLowerCase();
      fetchLoginProvider({ providerUrlName: normalizedProvider }, true);
    }
  }, [provider, fetchLoginProvider]);

  const navigateWithProvider = useNavigateWithProvider()

  // set the second logo when login provider is loaded
  useEffect(() => {
    setLogoToDisplay(loginProvider?.logoUrl);
  }, [loginProvider]);

  const onLogin = () => {
    mutate({
      loginRequest: {
        username: email,
        keepMeLoggedIn: true,
        password,
      },
    });
  };

  useEffect(() => {
    if (isSuccess && data) {
      const { email, firstName, lastName } = data;

      if (firstName && email && lastName) {
        console.log("data");

        dispatch(
          login({
            email,
            firstName,
            lastName,
            isTeacher: get("isTeacher", data) || false,
          })
        );

        fetchOrgs();
      }
    }
  }, [data, dispatch, isSuccess, fetchOrgs]);

  useEffect(() => {
    if (orgsData) {
      const firstCourse = get([0, "courses", 0, "id"], orgsData);

      if (firstCourse) {
        navigateWithProvider(`/course/${firstCourse}`);
      }
    }
  }, [orgsData, navigateWithProvider]);

  return (
    <Box
      width={200}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {process.env.NODE_ENV === "test" && <h2>Testing</h2>}
      {process.env.NODE_ENV === "development" && <h2>Development</h2>}
      <Box
        width={200}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          gap: 1,
          paddingBottom: 2,
        }}
      >
        {/* Display the  dynamic logo based on the provider */}
        {logoToDisplay?.map((logoUrl) => (
          <LogoBox imageSrc={logoUrl} imageHeight={70} />
        ))}
        <LogoBox imageSrc={TAUGA_AI_LOGO_1} imageHeight={70} />
      </Box>
      <Card size="lg">
        <Input
          disabled={isLoading || isLoadingOrgs}
          value={email}
          onChange={(v) => setEmail(v.target.value)}
          placeholder="אימייל"
          type="email"
        />
        <Input
          disabled={isLoading || isLoadingOrgs}
          value={password}
          onChange={(v) => setPassword(v.target.value)}
          type="password"
          placeholder="סיסמה"
        />
        <Button
          disabled={!email || !password || isLoading || isLoadingOrgs}
          onClick={onLogin}
        >
          {!isLoading || !isLoadingOrgs ? "התחבר" : <CircularProgress />}
        </Button>
      </Card>

      {isError && (
        <Box>
          <Typography>
            שם המשתמש או הסיסמה שגויים, אנא נסה שוב או שלח אימייל ל
            Idan@Tauga.ai
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default Login;
