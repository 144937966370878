// store/providerSlice.js
import { createSlice } from "@reduxjs/toolkit";

const providerSlice = createSlice({
  name: "provider",
  initialState: { provider: null },
  reducers: {
    setProvider(state, action) {
      state.provider = action.payload;
    },
    clearProvider(state) {
      state.provider = null;
    },
  },
});

export const { setProvider, clearProvider } = providerSlice.actions;
export default providerSlice.reducer;
