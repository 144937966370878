import { RootState } from "../..";
import { createSelector } from "@reduxjs/toolkit";

export const userSelector = createSelector(
  [(state: RootState) => state.session],
  (state) => state.user
);

export const currentOrganizationSelector = createSelector(
  [(state: RootState) => state.session],
  (state) => state.currentOrganization
);
