// hooks/useNavigateWithProvider.ts
import { useNavigate, useParams } from "react-router-dom";

const useNavigateWithProvider = () => {
  const navigate = useNavigate();
  //   const provider = useSelector((state: RootState) => state.provider.provider);
  const { provider } = useParams();

  const navigateWithProvider = (path: string, options = {}) => {
    const newPath = provider ? `/${provider}${path}` : path;
    navigate(newPath, options);
  };

  return navigateWithProvider;
};

export default useNavigateWithProvider;
