import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Box, Button, Typography } from "@mui/joy";
import Icon from "../../../components/icon";
import { Edit, List, SportsMartialArts } from "@mui/icons-material";
import { themeBase } from "../../../theme";
import BasicModal from "../../../components/basic-modal";
import ExerciseFilterPicker from "../components/exercise-filter-picker";
import { useEffect, useState } from "react";
import { useReward } from "react-rewards";
import { umbracoApi } from "../../../store/api/umbraco-api";
import useNavigateWithProvider from "../../../components/hooks/useNavigateWithProvider";

const FeatureFinished = () => {
  const location = useLocation();
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [fetch] = umbracoApi.useLazyGetCoursesByIdQuery();
  const { courseId } = useParams();
  const navigateWithProvider = useNavigateWithProvider();
  const { reward } = useReward("rewardId", "emoji", {
    emoji: ["💯", "🙌", "🥳", "🪅"],
  });

  useEffect(() => {
    fetch({ id: parseInt(courseId as string) });
    reward();
  }, [courseId]);

  const continueStudy = () => {
    if (location.pathname.includes("exercise"))
      return navigateWithProvider(`/course/${courseId}/exercises`);
    return navigateWithProvider(`/course/${courseId}/smart-lessons`);
  };

  const navigateToSmartLesson = () => {
    if (courseId !== "1144")
      return navigateWithProvider(`/course/${courseId}/exercises`);
    return navigateWithProvider(`/course/${courseId}/exercise/1189`);
  };

  return (
    <Box display="flex" flexDirection="column" sx={{ gap: 5 }}>
      <Typography level="title-lg">
        כל הכבוד, סיימת את הפרק! הידע שלך הוא ב 15% העליונים של התלמידים בפרק
        זה, נראה שאתה תותח אמיתי בניהול זמן! אנחנו ממליצים לך לעבור לתרגול הבינה
        המלאכותית כדי להמשיך לתרגל, או לחילופין לעבור לשיעור החכם הבא
      </Typography>
      <Icon
        component={SportsMartialArts}
        sx={{ fontSize: 60 }}
        htmlColor={themeBase.colorSchemes.light.palette.primary[500]}
      />
      <Box sx={{ gap: 2, display: "flex", flexDirection: "row" }}>
        <BasicModal
          title="תרגול לפי נושאים"
          open={filterModalOpen}
          onClose={() => setFilterModalOpen(false)}
        >
          <ExerciseFilterPicker />
        </BasicModal>

        <Button
          sx={{
            minWidth: 150,
            display: "flex",
            flexDirection: "row",
            pr: 1,
            gap: 1,
            justifyContent: "space-between",
            alignItems: "center",
          }}
          variant="solid"
          onClick={navigateToSmartLesson}
        >
          <Icon component={Edit} />
          <Typography textColor="white" level="title-lg">
            תרגול בינה מלאכותית
          </Typography>
        </Button>
        <Button
          sx={{
            minWidth: 150,
            display: "flex",
            flexDirection: "row",
            pr: 1,
            gap: 1,
            justifyContent: "space-between",
            alignItems: "center",
          }}
          variant="outlined"
          onClick={continueStudy}
        >
          <Icon component={List} />

          <Typography color="primary" level="title-lg">
            {location.pathname.includes("exercise")
              ? "לבחירת השיעור החכם הבא"
              : "לבחירת התרגול החכם הבא"}
          </Typography>
        </Button>
      </Box>
      <span style={{ alignSelf: "center" }} id="rewardId" />
    </Box>
  );
};

export default FeatureFinished;
