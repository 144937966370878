import {
  Box,
  Checkbox,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/joy";
import { useEffect, useMemo, useState } from "react";
import { animated, useSpringValue } from "@react-spring/web";
import { useReward } from "react-rewards";
import { Button, Card } from "..";
import RichText from "../rich-text";

interface Question {
  id: number;
  title: string;
  answers: string[];
  correctAnswer?: number;
  solution?: string | null;
  question: string;
}

interface ExerciseQuestionProps extends Question {
  multipleChoice?: boolean;
  onAnswerClick?: boolean;
  disabled?: boolean;
  onChoiceChanged(choiceIndex: number): void;
  setUnitFinished(finish: boolean): void;
  setUnitComplete(args: any): void;
}

interface ChoiceProps {
  content: string;
  disabled: boolean;
  multipleChoice?: boolean;
  onChange(index: number): void;
  id: number;
}

const Choice = ({
  content,
  multipleChoice,
  disabled,
  onChange,
  id,
}: ChoiceProps) => {
  const onClick = () => {
    onChange(id);
  };

  if (multipleChoice)
    return (
      <FormLabel>
        <Card sx={{ flexDirection: "row" }}>
          <Checkbox
            key={id}
            onChange={onClick}
            value={content}
            disabled={disabled}
          />
          <RichText content={content} />
        </Card>
      </FormLabel>
    );

  return (
    <FormLabel>
      <Card sx={{ flexDirection: "row" }}>
        <Radio
          key={id}
          onChange={onClick}
          value={content}
          disabled={disabled}
        />
        <RichText content={content} />
      </Card>
    </FormLabel>
  );
};

enum QuestionState {
  ANSWERING,
  WRONG_ANSWER,
  CORRECT_ANSWER,
}

const ExerciseQuestion = ({
  multipleChoice = false,
  answers,
  disabled = false,
  onChoiceChanged,
  title,
  solution,
  question,
  correctAnswer,
  setUnitFinished,
  setUnitComplete,
}: ExerciseQuestionProps) => {
  const [chosen, setChosen] = useState<null | number>(null);
  const x = useSpringValue(0, {
    config: {
      mass: 0.5,
      friction: 2,
    },
  });
  const { reward } = useReward("rewardId", "emoji", {
    emoji: ["💯", "🙌", "🥳", "🪅"],
  });

  const [questionState, setQuestionState] = useState<QuestionState>(
    QuestionState.ANSWERING
  );

  const isAnswered = useMemo(
    () => questionState !== QuestionState.ANSWERING,
    [questionState]
  );

  const onAnswer = () => {
    if (chosen !== null) {
      if (chosen === correctAnswer) {
        setQuestionState(QuestionState.CORRECT_ANSWER);
        reward();
      } else {
        setQuestionState(QuestionState.WRONG_ANSWER);
        shake();
      }
      setUnitComplete({ answerIndex: chosen });
    }
  };

  const shake = () => {
    x.start({ from: 10, to: 0 });
  };

  useEffect(() => {
    if (chosen !== null) {
      onChoiceChanged(chosen);
    }
  }, [chosen, onChoiceChanged]);

  useEffect(() => {
    setUnitFinished(isAnswered);
  }, [isAnswered, setUnitFinished]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        maxWidth: "600px",
      }}
    >
      {title && <Typography level="title-lg">{title}</Typography>}

      <RichText content={question} />

      <animated.div
        style={{
          x,
        }}
      >
        <RadioGroup name="answers" sx={{ gap: 1 }}>
          {answers.map((ans, index) => (
            <span key={ans}>
              <Choice
                onChange={setChosen}
                id={index}
                disabled={isAnswered}
                multipleChoice={false}
                content={ans}
              />
            </span>
          ))}
        </RadioGroup>
      </animated.div>

      {isAnswered && (
        <Card>
          {questionState === QuestionState.CORRECT_ANSWER ? (
            <Typography color="primary">תשובה נכונה</Typography>
          ) : (
            <Typography color="danger">הפעם טעית</Typography>
          )}

          {solution && <RichText content={solution} />}
        </Card>
      )}

      {!isAnswered && (
        <Button
          sx={{
            width: 150,
            justifySelf: "flex-start",
            alignSelf: "flex-start",
          }}
          disabled={chosen === null}
          onClick={onAnswer}
          variant="outlined"
        >
          שלח תשובה
        </Button>
      )}

      <span style={{ alignSelf: "center" }} id="rewardId" />
    </Box>
  );
};

export default ExerciseQuestion;
