import { useNavigate, useParams } from "react-router-dom";
import { Box } from "../../../components";

import { LinearProgress, Typography } from "@mui/joy";
import {
  Feature,
  useGetCoursesByIdQuery,
} from "../../../store/api/umbraco-api";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { FeatureType, RenderFeature } from "../components/feature-info";
import { useMemo, useState } from "react";

import ExerciseFilterPicker from "../components/exercise-filter-picker";
import BasicModal from "../../../components/basic-modal";
import { groupBy, keys } from "lodash/fp";
import useNavigateWithProvider from "../../../components/hooks/useNavigateWithProvider";

const featureNavigation = (
  type?: FeatureType,
  courseId?: string,
  featureId?: number
) => {
  const string = `/course/${courseId}`;

  switch (type) {
    case FeatureType.Exercise:
      return string + `/exercise/${featureId}`;
    default:
      return string + `/feature/${featureId}`;
  }
};

const Exercises = () => {
  const navigateWithProvider = useNavigateWithProvider();
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const { courseId = "" } = useParams();
  const {
    data: course,
    isError,
    error,
    isLoading,
  } = useGetCoursesByIdQuery({
    id: parseInt(courseId || "0"),
  });

  const groupedExercises: { [key: string]: Feature[] } = useMemo(() => {
    if (course?.features?.length && course.features.length > 0) {
      const filteredExercises = course.features.filter(
        ({ type }) => (type as unknown) === FeatureType.Exercise
      );

      return groupBy(({ group }) => group || "כללי", filteredExercises);
    }

    return {};
  }, [course]);

  if (isError) {
    if ((error as FetchBaseQueryError)?.status === 404) {
      navigateWithProvider("/404");
    }
  }

  if (isLoading) {
    return <LinearProgress sx={{ width: "600px" }} />;
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      flexShrink={1}
      sx={{ gap: 2, maxWidth: "600px" }}
    >
      <Box>
        <Typography level="title-lg">תרגולי בינה מלאכותית</Typography>
      </Box>

      <BasicModal
        title="תרגול לפי נושאים"
        open={filterModalOpen}
        onClose={() => setFilterModalOpen(false)}
      >
        <ExerciseFilterPicker />
      </BasicModal>

      {keys(groupedExercises).map((key) => {
        return (
          <Box key={key}>
            <Typography level="title-sm" sx={{ mb: 1 }} color="primary">
              {key}
            </Typography>
            <ExerciseList
              exercises={groupedExercises[key]}
              courseId={courseId}
            />
          </Box>
        );
      })}
    </Box>
  );
};

const ExerciseList = ({
  exercises,
  courseId,
}: {
  exercises: Feature[];
  courseId: string;
}) => {
  const navigateWithProvider = useNavigateWithProvider();

  return (
    <Box sx={{ gap: 2, display: "flex", flexDirection: "column" }}>
      {exercises.map((feature) => (
        <RenderFeature
          {...feature}
          key={feature.id}
          courseId={courseId}
          onClick={() =>
            navigateWithProvider(
              featureNavigation(
                feature.type as unknown as FeatureType,
                courseId,
                feature.id
              )
            )
          }
        />
      ))}
    </Box>
  );
};

export default Exercises;
