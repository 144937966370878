import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { CssVarsProvider } from "@mui/joy";
import { persistor, store } from "./store";
import UnauthenticatedRoute from "./components/route-guards/unauthenticated-route";
import AuthenticatedRoute from "./components/route-guards/authenticated-route";
import CourseContent from "./screens/course";
import Feature from "./screens/course/feature";
import Login from "./screens/login";
import Home from "./screens/home";
import { theme } from "./theme";
import NotFound from "./screens/not-found";
import SmartLessons from "./screens/course/course-content/smart-lessons.tsx";
import Exercises from "./screens/course/course-content/exercises";
import CourseAdmin from "./screens/course/admin";
import Signup from "./screens/signup";
import { useEffect } from "react";
import ReactGA from "react-ga4";

const queryClient = new QueryClient({});

const withOptionalProvider = (path: string, element: JSX.Element) => [
  { path, element },
  { path: `/:provider${path}`, element },
];

// Unauthenticated routes
const unauthenticatedRoutes = [
  ...withOptionalProvider("/login", <Login />),
  ...withOptionalProvider("/signup", <Signup />),
];

// Authenticated routes
const authenticatedRoutes = [
  ...withOptionalProvider("/", <Home />),
  ...withOptionalProvider("/course/:courseId", <CourseContent />),
  // ...withOptionalProvider("/course/:courseId/statistics", <CourseAdmin />),
  ...withOptionalProvider("/course/:courseId/smart-lessons", <SmartLessons />),
  ...withOptionalProvider("/course/:courseId/exercises", <Exercises />),
  ...withOptionalProvider("/course/:courseId/exercise/:featureId", <Feature />),
  ...withOptionalProvider("/course/:courseId/feature/:featureId", <Feature />),
  { path: "*", element: <NotFound /> },
];

// Combine into router
const router = createBrowserRouter([
  {
    element: <UnauthenticatedRoute />,
    children: unauthenticatedRoutes,
  },
  {
    element: <AuthenticatedRoute />,
    children: authenticatedRoutes,
  },
]);

function App() {
  useEffect(() => {
    ReactGA.initialize("G-VPV7SFBWV6");
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <CssVarsProvider theme={theme} />
        <QueryClientProvider client={queryClient}>
          <RouterProvider router={router} />
        </QueryClientProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
