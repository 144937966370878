import { Box, Card } from "../components";
import { Gender, usePostAuthSignupMutation } from "../store/api/umbraco-api";
import { useEffect, useState } from "react";
import { useFetcher, useNavigate, useParams } from "react-router-dom";
import { Select, MenuItem, CircularProgress, TextField } from "@mui/material";

import LogoBox from "../components/header/logo-box";
import { TAUGA_AI_LOGO_1 } from "../images/images";
import { Button } from "@mui/joy";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import useNavigateWithProvider from "../components/hooks/useNavigateWithProvider";

// // Type guard function to check if error has data
// const isFetchBaseQueryError = (
//   error: any
// ): error is FetchBaseQueryError => {
//   return error && typeof error === "object" && "data" in error;
// };

const Signup = () => {
  const navigateWithProvider = useNavigateWithProvider()
  const { provider } = useParams<string>();
  const signupProvider = provider ?? "defaultProvider";

  const [mutate, { data, error, isLoading }] = usePostAuthSignupMutation();


  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [yearOfBirth, setYearOfBirth] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [gender, setGender] = useState(1);

  // TextField validation errors
  const [validationErrors, setValidationErrors] = useState<{
    [key: string]: string;
  }>({});

  const genderMap: { [key: number]: Gender } = {
    0: "Other",
    1: "Male",
    2: "Female",
  };

  const genderValue = genderMap[gender];

  const onSignup = () => {
    // Reset validation errors
    setValidationErrors({});

    // Basic client-side validation
    let errors: { [key: string]: string } = {};
    if (!email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
      errors.email = "Please provide a valid email address.";
    }
    //SPECIAL CHAR!!!
    if (!password.match(/^(?=.*[a-zA-Z])(?=.*\d)[A-Za-z\d]{8,}$/)) {
      console.log("password!!!", password);
      errors.password =
        "Password must be at least 8 characters long and contain both letters and digits.";
    }

    if (!firstName || firstName.length > 50) {
      errors.firstName =
        "First name is required and cannot exceed 50 characters.";
    }
    if (!lastName || lastName.length > 50) {
      errors.lastName =
        "Last name is required and cannot exceed 50 characters.";
    }
    if (
      yearOfBirth &&
      (parseInt(yearOfBirth) < 1900 ||
        parseInt(yearOfBirth) > new Date().getFullYear())
    ) {
      errors.yearOfBirth =
        "Year of birth must be between 1900 and the current year.";
    }

    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }

    // Make signup API call
    mutate({
      signupRequest: {
        signupProvider,
        email,
        password,
        firstName,
        lastName,
        yearOfBirth: yearOfBirth ? parseInt(yearOfBirth) : undefined,
        phoneNumber: phoneNumber || undefined,
        gender: genderValue,
      },
    }).then((response) => {
      if (response.data) {
        navigateWithProvider("/auth/login");
      }
    });
  };

  return (
    <Box
      width={300}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        width={200}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          gap: 1,
          paddingBottom: 2,
        }}
      >
        <LogoBox imageSrc={TAUGA_AI_LOGO_1} imageHeight={70} />
      </Box>
      <Card
        sx={{
          width: "90%", // Use 90% of the available width
          maxWidth: 500, // Maximum width of 500px
        }}
        size="lg"
      >
        <TextField
          value={email}
          onChange={(v) => setEmail(v.target.value)}
          placeholder="מייל"
          type="email"
          error={!!validationErrors.email}
          helperText={validationErrors.email}
        />
        <TextField
          value={password}
          onChange={(v) => setPassword(v.target.value)}
          type="password"
          placeholder="סיסמא"
          error={!!validationErrors.password}
          helperText={validationErrors.password}
        />
        <TextField
          value={firstName}
          onChange={(v) => setFirstName(v.target.value)}
          placeholder="שם פרטי "
          error={!!validationErrors.firstName}
          helperText={validationErrors.firstName}
        />
        <TextField
          value={lastName}
          onChange={(v) => setLastName(v.target.value)}
          placeholder="שם משפחה"
          error={!!validationErrors.lastName}
          helperText={validationErrors.lastName}
        />
        <TextField
          value={yearOfBirth}
          onChange={(v) => setYearOfBirth(v.target.value)}
          placeholder="שנת לידה"
          type="number"
          error={!!validationErrors.yearOfBirth}
          helperText={validationErrors.yearOfBirth}
        />
        <TextField
          value={phoneNumber}
          onChange={(v) => setPhoneNumber(v.target.value)}
          placeholder="טלפון (אופציונלי)"
          type="text"
        />
        <Select
          value={gender}
          onChange={(e) => setGender(Number(e.target.value))}
        >
          <MenuItem value={0}>Other</MenuItem>
          <MenuItem value={1}>Male</MenuItem>
          <MenuItem value={2}>Female</MenuItem>
        </Select>
        <Button disabled={isLoading} onClick={onSignup}>
          {!isLoading ? "הרשמה" : <CircularProgress />}
        </Button>
      </Card>

      {/* {isFetchBaseQueryError(error) && (
        <Box>
          <Typography>
            {error.data?.message ||
              "An error occurred during signup. Please try again later."}
          </Typography>
        </Box>
      )} */}
    </Box>
  );
};

export default Signup;
