import { useDispatch, useSelector } from "react-redux";
import { Box, IconButton } from "@mui/joy";
import { Person, Menu as MenuIcon } from "@mui/icons-material";
import Avatar from "../avatar";
import { persistor } from "../../store";
import { Divider, Dropdown, Menu, MenuItem, Typography } from "@mui/joy";
import { MenuButton } from "@mui/joy";
import { logout } from "../../store/session/actions/user.actions";
import {
  umbracoApi,
  usePostAuthLogoutMutation,
} from "../../store/api/umbraco-api";
import { useNavigate, useParams } from "react-router-dom";
import {
  currentOrganizationSelector,
  userSelector,
} from "../../store/session/selectors";
import { TAUGA_AI_LOGO_1 } from "../../images/images";
import { useEffect, useState } from "react";
import ResponsiveLogoBox from "../ResponsiveLogoBox";
import useNavigateWithProvider from "../hooks/useNavigateWithProvider";

interface HeaderProps {
  onToggleSidebar: () => void;
}

const Header: React.FC<HeaderProps> = ({ onToggleSidebar }) => {
  const user = useSelector(userSelector);
  const currentOrganization = useSelector(currentOrganizationSelector);

  const { provider } = useParams();
  const [mutate] = usePostAuthLogoutMutation();
  const dispatch = useDispatch();
  const [logoToDisplay, setLogoToDisplay] = useState<string[] | undefined>(); // Updated to array of strings
  const [fetchLoginProvider, { data: loginProvider }] =
    umbracoApi.useLazyGetLoginProvidersByProviderUrlNameQuery();

  const navigateWithProvider = useNavigateWithProvider();

  useEffect(() => {
    if (provider) {
      const normalizedProvider = provider.toLowerCase();
      fetchLoginProvider({ providerUrlName: normalizedProvider }, true);
    }
  }, [provider, fetchLoginProvider]);

  useEffect(() => {
    if (loginProvider?.logoUrl && Array.isArray(loginProvider.logoUrl)) {
      setLogoToDisplay(loginProvider.logoUrl);
    }
  }, [loginProvider]);

  const onPressLogout = async () => {
    try {
      await mutate().unwrap();
      dispatch(logout());
      persistor.purge();
      navigateWithProvider("login");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box
      position={"relative"}
      padding={"15px"}
      height={55}
      flexDirection={"row"}
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
      borderBottom="1px solid lightgray"
    >
      <Box sx={{ flexDirection: "row", display: "flex", gap: 2 }}>
        <IconButton
          sx={{ display: { xs: "block", md: "none" } }}
          onClick={onToggleSidebar}
        >
          <MenuIcon />
        </IconButton>
        <ResponsiveLogoBox imageSrc={TAUGA_AI_LOGO_1} />
        {currentOrganization?.logo && (
          <ResponsiveLogoBox imageSrc={currentOrganization.logo} />
        )}
        {logoToDisplay?.map((logo, index) => (
          <ResponsiveLogoBox key={index} imageSrc={logo} />
        ))}
      </Box>

      {user && (
        <Dropdown>
          <MenuButton
            slots={{ root: Avatar }}
            slotProps={{ root: { color: "neutral" } }}
          >
            <Avatar color="primary">
              <Person />
            </Avatar>
          </MenuButton>
          <Menu sx={{ textAlign: "center" }}>
            <Typography level="body-xs">שלום {user.firstName}</Typography>
            <Divider />
            <MenuItem onClick={onPressLogout}>התנתק</MenuItem>
          </Menu>
        </Dropdown>
      )}
    </Box>
  );
};

export default Header;
