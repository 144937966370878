import { useNavigate, useParams } from "react-router-dom";
import {
  umbracoApi,
  usePostFeaturesByFeatureIdMutation,
  usePostFeaturesByFeatureIdUnitsAndUnitIdSkipMutation,
} from "../../../store/api/umbraco-api";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Button, LinearProgress, Typography } from "@mui/joy";
import Unit from "../../../components/feature-units/unit";
import FeatureFinished from "./feature-finished";
import SimpleProgressBar from "../../../components/common/simple-progress-bar";
import Chat from "../../../components/chat";
import {
  isMultiChoiceExercise,
  isOpenResponseExercise,
  UnitTypes,
} from "../../../components/feature-units/unit.types";
import useNavigateWithProvider from "../../../components/hooks/useNavigateWithProvider";

const Feature = () => {
  const navigateWithProvider = useNavigateWithProvider();
  const { featureId } = useParams();
  const [unitFinished, setUnitFinished] = useState(false);
  const [unitCompleteObject, setUnitCompleteObject] = useState({});

  const [fetch, { data, isFetching, isError }] =
    umbracoApi.useLazyGetFeaturesByFeatureIdQuery();
  const [completeUnit, { isSuccess: isPostSuccess }] =
    usePostFeaturesByFeatureIdMutation();
  const [skipUnit, { isSuccess: isSkipSuccess }] =
    usePostFeaturesByFeatureIdUnitsAndUnitIdSkipMutation();

  const postUnitComplete = useCallback(() => {
    completeUnit({
      featureId: featureId as unknown as number,
      iUnitCompleted: unitCompleteObject,
    });
  }, [featureId, completeUnit, unitCompleteObject]);

  const postSkipUnit = useCallback(() => {
    if (data?.unit?.id) {
      skipUnit({
        featureId: featureId as unknown as number,
        unitId: data?.unit?.id,
      });
    }
  }, [data?.unit?.id, featureId, skipUnit]);

  useEffect(() => {
    fetch({ featureId: parseInt(featureId as string) });
  }, [featureId, fetch]);

  useEffect(() => {
    if (isPostSuccess || isSkipSuccess) {
      fetch({ featureId: parseInt(featureId as string) });
    }
  }, [isPostSuccess, isSkipSuccess, featureId, fetch]);

  const canSkip = useMemo(
    () =>
      !unitFinished &&
      data?.unit &&
      (isMultiChoiceExercise(data.unit) || isOpenResponseExercise(data.unit)),
    [data?.unit, unitFinished]
  );

  if (isError) {
    navigateWithProvider("/404");
  }

  if (isFetching) {
    return <LinearProgress sx={{ width: "600px" }} />;
  }

  if (!data || !data.feature) {
    return <Typography>שגיאה בלתי צפויה</Typography>;
  }

  const { feature } = data;

  const { unitsCompleted = 0, unitsTotal = 1, status } = feature;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      {/* {(data?.unit as any)?.isChat ? (
        <Chat resourceId={data?.unit?.id || 0} />
      ) : (
        <></>
      )} */}
      <SimpleProgressBar
        title="התקדמות בשיעור"
        tooltipText="האחוזים הינם הערכה בלבד, שכן אורך הקורס המדויק מתאים את עצמו אליך תוך כדי תרגול"
        value={unitsCompleted}
        total={unitsTotal}
      />
      {unitsCompleted === unitsTotal ||
      (status as unknown as number) === 2 ||
      !data.unit ? (
        <FeatureFinished />
      ) : (
        <>
          <Unit
            setUnitFinished={setUnitFinished}
            onUnitCompleteChange={setUnitCompleteObject}
            unit={data.unit}
          />

          <Box
            sx={{
              display: "flex",
              gap: 1,
            }}
          >
            <Button
              sx={{
                width: 150,
              }}
              onClick={postUnitComplete}
            >
              המשך
            </Button>

            {/* Temporarily remove canSkip condition */}
            <Button
              sx={{
                width: 100,
                fontSize: "0.8rem",
                color: "gray",
                borderColor: "gray",
                backgroundColor: "transparent",
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                },
              }}
              onClick={postSkipUnit}
              variant="outlined"
            >
              דלג
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};

export default Feature;
