import { Box, Button, Checkbox, Sheet, Slider, Typography } from "@mui/joy";
import { useCallback } from "react";
import { isArray, isEmpty, flatten } from "lodash/fp";
import useMultipleSelection from "../../../components/multiple-selection-hook";
import { useNavigate } from "react-router-dom";
import useNavigateWithProvider from "../../../components/hooks/useNavigateWithProvider";

const Subject = ({
  ident = 0,
  checked = false,
  label,
  onClick,
  indeterminate = false,
}: {
  ident?: number;
  checked?: boolean;
  label: string;
  onClick(): void;
  indeterminate?: boolean;
}) => {
  return (
    <Checkbox
      indeterminate={indeterminate}
      onClick={onClick}
      checked={checked}
      sx={{
        pr: `${ident * 1.5}em`,
      }}
      label={label}
    />
  );
};

const marks = [
  {
    value: 0,
    label: "קשה",
  },
  {
    value: 1,
    label: "בינוני",
  },
  {
    value: 2,
    label: "קל",
  },
];

const rothcildExercise = "1188";

type TopicFilter = string | string[];

const items: TopicFilter[] = [
  [
    "ניהול הזמן",
    "הצבת מטרות",
    "הצבת יעדים שנתיים, חודשיים ושבועיים",
    "איך להתמודד עם מטרות מאתגרות במיוחד?",
    "איך לתעדף משימות?",
    "משמעת עצמית",
    "איך לא לשכוח משימות?",
    "בניית לוז",
    "הצבת מחסומים עצמיים",
    "שיטת 2 הדקות",
    "שיטת פומודורו",
    "תחקור והשתתפות",
  ],
  "חסכונות והשקעות",
  "קבלת החלטות",
  "עמידה מול קהל",
  "איך לבחור משרת סטודנט",
  "הכנה לראיונות עבודה",
  "יישום בינה מלאכותית בחיי הסטודנט",
  "הסטודנט המילואימניק",
];

const ExerciseFilterPicker = () => {
  const navigateWithProvider = useNavigateWithProvider();
  const { toggleItem, selected, setSelected } = useMultipleSelection({
    getKey: (item) => item,
  });

  const selectAll = useCallback(() => {
    setSelected(flatten(items));
  }, [items, setSelected]);

  const clearSelection = useCallback(() => {
    setSelected([]);
  }, [setSelected]);

  return (
    <Box display="flex" flexDirection="column" sx={{ gap: 1 }}>
      <Typography level="body-sm" mb={1}>
        בחר את הנושאים שברצונך לתרגל (נושאים שעדיין לא למדת נעולים)
      </Typography>

      <Box display="flex" flexDirection="column" sx={{ gap: 1 }} width={500}>
        {items.map((item) =>
          isArray(item) ? (
            <>
              <Subject
                indeterminate={selected.includes(item[0])}
                ident={0}
                key={item[0]}
                label={item[0]}
                onClick={() => toggleItem(item[0])}
              />
              {selected.includes(item[0]) &&
                item.slice(1).map((i) => (
                  <Sheet>
                    <Subject
                      ident={1}
                      key={i}
                      label={i}
                      checked={selected.includes(`${i}`)}
                      onClick={() => toggleItem(`${i}`)}
                    />
                  </Sheet>
                ))}
            </>
          ) : (
            <Subject
              ident={0}
              key={item}
              label={item}
              checked={selected.includes(item)}
              onClick={() => toggleItem(item)}
            />
          )
        )}
      </Box>

      <Box
        display="flex"
        flexDirection="row"
        sx={{ justifyContent: "center", mt: 1, mb: 1, gap: 2 }}
      >
        <Button color="primary" variant="outlined" onClick={selectAll}>
          סמן הכל
        </Button>
        <Button color="primary" variant="outlined" onClick={clearSelection}>
          בטל בחירה
        </Button>
      </Box>

      <Box>
        <Typography level="body-sm" mb={2}>
          בחר את דרגת הקושי של השאלות
        </Typography>

        <Slider
          sx={{ mt: -2, mb: 2 }}
          track="inverted"
          defaultValue={1}
          step={1}
          min={0}
          max={2}
          marks={marks}
        />
      </Box>

      <Button
        onClick={() => navigateWithProvider(`exercise/${rothcildExercise}`)}
        disabled={isEmpty(selected)}
      >
        התחל תרגול
      </Button>
    </Box>
  );
};

export default ExerciseFilterPicker;
