import { Box } from "@mui/joy";

interface ResponsiveLogoBoxProps {
  imageSrc: string;
}

const ResponsiveLogoBox: React.FC<ResponsiveLogoBoxProps> = ({ imageSrc }) => {
  return (
    <Box
      component="img"
      src={imageSrc}
      alt="logo"
      sx={{
        width: { xs: "30px", sm: "40px", md: "50px" }, // Adjusts size based on screen size
        height: "auto",
        objectFit: "contain",
      }}
    />
  );
};

export default ResponsiveLogoBox;
